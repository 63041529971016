<template>
    <div>

        <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>

        <div v-if="!cargando">

            <div class="d-flex flex-row bd-highlight mb-2">
                <div class="me-3">
                    <img  style="max-height: 110px; border: solid 5px rgb(243, 243, 243);  border-radius: 15px;" class="float-start border-3"  :src="archivospublicos+'/personas/'+infopersona.foto" alt="">
                </div>
                <div>
                    <span style="font-size: 11px;" class="titulosg">ESTUDIANTE</span> <br>
                    <span class="h4 titulosg text-uppercase">{{infopersona.nombres}} {{infopersona.apellidos}}</span> <br>
                    
                    <span  v-if="infopersona.estado==1" class="badge bg-success">Estado Activo</span>
                    <span  v-if="infopersona.estado==0" class="badge bg-secondary">Estado Inactivo</span> 
                    <div class="mt-3">
                        <router-link  :to="{ name: 'Editarpersona', params: { id: infopersona.idpersonas }}" class="btn btn-primary btn-sm me-1">  <i class="fas fa-pencil-alt"></i> Editar   </router-link>
                        <router-link  :to="{ name: 'Editarpersona', params: { id: infopersona.idpersonas }}" class="btn btn-primary btn-sm"> <i class="fas fa-camera"></i> Cambiar Foto   </router-link>
                    </div>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="p-3 card bg-white rounded-3" >
          
                        <table class="table table-borderless table-sm">
                            <tbody>
                            <tr>                                   
                                <td  class="text-secondary">Identificación:</td>
                                <td  > {{infopersona.identificacion}}</td>                                
                            </tr>
                            <tr>
                                <td class="text-secondary">Tipo de Identificación:</td>
                                <td>  {{tipodocumento[infopersona.tipoidentificacion]}} </td>
                            </tr>
                            <tr>
                                <td class="text-secondary"> Fecha de Expedición: </td>
                                <td> {{infopersona.fechaexpedicion}}   </td>
                            </tr>
                            <tr>
                                <td class="text-secondary">  Lugar de Expedición: </td>
                                <td>  {{infopersona.ciudadexpedicion.nombreciudad}}   {{infopersona.ciudadexpedicion.nombredepartamento}}</td>
                            </tr>
                            <tr>                      
                                <td class="text-secondary">Fecha de Nacimiento:</td>
                                <td>{{infopersona.fechanacimiento}}   </td>
                            </tr>
                            <tr>                      
                                <td class="text-secondary">Lugar de Nacimiento:</td>
                                <td> {{infopersona.ciudadnacimiento.nombreciudad}} {{infopersona.ciudadnacimiento.nombredepartamento}} </td>
                            </tr>
                            <tr v-if="infopersona.personascategoria_idpersonascategoria != 4">
                                <td class="text-secondary">Estado Civil:</td>
                                <td> {{ estadocivil[infopersona.estadocivil] }}</td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Composición Familiar:</td>
                                <td>{{infopersona.composicionfamiliar}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    
                        <div class="row row-cols-3 mt-2">
                            <div class="col pe-0">  
                                <div class="p-2 ps-3" style="background-color: rgb(229, 232, 242); border-radius: 10px;">
                                    <i class="fas fa-tachometer-alt"></i>  Edad: <br>
                                    <strong>{{  edad(infopersona.fechanacimiento ) }}  </strong>
                                </div>                              
                            </div>
                            <div class="col pe-0">
                                <div class="p-2 ps-3" style="background-color: rgb(229, 232, 242); border-radius: 10px;">
                                    <i class="fab fa-discourse"></i> Tipo y RH: <br>
                                    <span class="titulosg">  {{infopersona.tiposangre}} </span> 
                                </div>
                            </div>
                            <div class="col">  
                                <div class="p-2 ps-3" style="background-color: rgb(229, 232, 242); border-radius: 10px;">
                                    <i class="fas fa-venus-mars"></i>   Sexo:  <br>
                                    <span class=" titulosg" v-if="infopersona.genero=='H'">Hombre</span>
                                    <span class=" titulosg" v-if="infopersona.genero=='M'">Mujer</span>
                                </div>                              
                            </div>
                        </div>
                </div>
  
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="card p-3 rounded-3">

                        <table class="table table-borderless table-sm mb-0">
                        <tbody>
                            <tr>
                                <td   class="text-secondary">Celular:</td>
                                <td >
                                    {{infopersona.celular}}
                                    <a title="escribir" :href="'https://wa.me/57'+infopersona.celular" target="_blank" class="ml-2"> <i class="fab fa-whatsapp text-success fa-lg "></i> </a>
                                    <a title="llamar" :href="'tel:'+infopersona.celular" class="ml-2"> <i class="ms-2 fas fa-mobile-alt fa-lg text-black"></i> </a>                                                                      
                                </td>                                
                            </tr>
                            <tr>
                                <td class="text-secondary">Teléfono Fijo:</td>
                                <td> {{infopersona.telefono}} <a title="llamar" :href="'tel:'+infopersona.telefono" class="ml-2"> <i class="ms-2 fas fa-mobile-alt fa-lg text-black"></i> </a></td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Correo Electónico:</td>
                                <td >{{infopersona.email}}</td>
                            </tr>

                            <tr>
                                <td class="text-secondary">Dirección:</td>
                                <td>{{infopersona.direccion}}</td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Barrio:</td>
                                <td> {{infopersona.barrio}}</td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Ciudad:</td>
                                <td>{{infopersona.ciudadresidencia.nombreciudad}}    {{infopersona.ciudadresidencia.nombredepartamento}} </td>
                            </tr>
                            <tr>
                                <td class="text-secondary">Estrato:</td>
                                <td>{{infopersona.estrato}}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                        <div  style="min-height: 70px;" class="card p-3 mt-2">

                            <img src="/images/seguridadfirma.png" class="position-absolute img-fluid" alt="" style="top: 0px;left:0px;">
                            <img v-if="infopersona.firma" :src="'/storage/personas/firmas/'+infopersona.firma" class="img-fluid" alt="">

                                <span class="text-secondary">Firma</span>  
                                <div>
                                    <span v-if="infopersona.autorizacionfirma==1" class="badge text-secondary" style="background-color: rgb(192, 238, 207);">Autorizado </span>
                                    <span v-if="infopersona.autorizacionfirma==0" class="badge bg-secondary"> Sin Autorización </span>
                                </div>
                        </div>
                </div>
            </div>
 
            <div class="mb-2">
                    <div class="card card-body rounded-3 mt-2" style="min-height: 70px;">
                        <strong>Observación:</strong>  
                        {{infopersona.observacion}}
                    </div>
            </div>

            <div class="row mb-2">
                    <div class="col-md-6" style="font-size: 10px;">
                        <div class="alert alert-light">
                        <i class="far fa-calendar-alt"></i> <strong class="me-3">  Fecha de Registro:</strong>   <span class="text-capitalize">{{ formatoFecha(infopersona.created_at) }}</span> <br>
                        </div>
                    </div>
                    <div class="col-md-6"  style="font-size: 10px;">
                        <div class="alert alert-light">
                         <i class="far fa-calendar-alt"></i>  <strong class="me-3"> Actualizado: </strong>  <span class="text-capitalize">{{ formatoFecha(infopersona.updated_at) }}</span>
                        </div>
                    </div>
            </div>

        </div> <!-- fin div carga-->


        <!-- Modal -->
        <div class="modal fade" id="modalcargarfoto" tabindex="-1" role="dialog" aria-labelledby="modalcargarfotoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="card-body">
                        <form v-on:submit.prevent="updatefoto">
                            <p class="text-center">
                                <img src="/imagenes/ico/foto.svg" width="100px">
                                <br>
                                Actualizar La Foto de Perfil <br>
                            </p>
                            <hr>
                            <input type="file" name="image" @change="getImage" accept="image/*" required>
                            <br>
                            <button type="submit" class="btn btn-sm btn-info mt-2"><i class="fas fa-upload"></i>
                                Cargar</button>
                        </form>

                        <div class="mt-2 alert alert-success" v-if="respuestacarga">
                            {{respuestacarga}}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>

import { ref, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'

export default {
    props: ['id'],
    setup(props) {
        
        const infopersona = ref([])

        const estadocivil = ref(['Soltero/a', 'Casado/a', 'En Relación ( más de 1 Año de noviazgo)', 'Comprometido/a', 'Unión libre o unión de hecho', 'Separado/a', 'Divorciado/a', 'Viudo/a.'])
        const tipodocumento = ref(['-', 'Cédula de Ciudadanía', 'Cédula de Extrangería', 'Pasaporte', 'Permiso Especial de Trabajo', 'RC - Registro Civil', 'TI - Tarjeta de identidad', 'NIP - Número de Identificación Personal', 'NUIP - Número Único de Identificación personal', 'NES - Número de Secretaría', 'PV - Pasaporte vigente', 'MS - Menor sin identificación'])
        const cargando = ref(true)
        const imagen = ref(null)
        const respuestacarga = ref(null)


        const edad = (date) => {
            return moment(new Date()).diff(date, 'years') + ' Años'
        }

        const formatoFecha = (date) => {
            return moment(date).format('MMMM D [de] YYYY, h:mm:ss a');
        }

        const consultarinformacion = () => {
            axios.get('/api/infopersona/'+props.id).then( response => {
                    cargando.value = false;
                    infopersona.value = response.data;
                })
        }

        // const getImage = (event) => {
        //     //Asignamos la imagen a  nuestra data
        //     imagen.value = event.target.files[0];
        //     respuestacarga.value = "";

        // }

        // const updatefoto = () => {
        //     var data = new FormData();
        //     data.append('foto', imagen.value);
        //     data.append('fotoanterior', infopersona.value.foto);
        //     data.append('_method', 'PUT');
        //     data.append('id', infopersona.value.idpersonas);
        //     axios.post('/api/cambiarimagen', data)
        //         .then(response => {
        //             infopersona.value.foto = response.data;
        //             respuestacarga.value = "Imagen cargada Correctamente";
        //         })
        // }


        onMounted(() => {
            consultarinformacion()
        })

        return {
            infopersona,
            estadocivil,
            tipodocumento,
            cargando,
            imagen,
            respuestacarga,

            //Funciones
            edad,
            formatoFecha
            }
    } 
}
</script>